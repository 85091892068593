import React from 'react';
import { useParams } from "react-router-dom"
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const ThankYou = () => {
  const navigate = useNavigate();
  const params = useParams()
  React.useEffect(() => {
    setTimeout(() => {
      navigate("/")
    }, 10000);
  }, [])

  return (
    <div className='p-[32px] flex flex-col items-center bg-[#E2E3F5] rounded-lg w-[80%] m-auto'>
      <h1 className='text-[64px] text-[#0131B9]'>Leave It with us “{params.name}”</h1>
      <p className='text-2xl'>we’ve got your request and we will contact you as soon as possible</p>
      <img src="/Desktop/thanku.png" alt="" className='px-[32px] py-[45px]' />
      <button
        className='bg-[#0131B9] text-white text-sm font-semibold px-8 py-3 rounded-lg mb-5'
        style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
        onClick={() => { navigate("/") }}
      >
        Back to website
      </button>
    </div>
  );
}

export default ThankYou;
