import { CiMail } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-scroll";
const Footers = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up"
      data-aos-duration="1000" className="flex flex-col justify-between w-full bg-[#F6F7FF] border border-t-[#111] mt-48 px-[100px] py-[32px]">
      <div>
        <Link to="home" smooth={true} offset={0} duration={500} onClick={handleNav}>
          <img
            className="w-[8rem] sm:w-[11.5rem] mb-8"
            alt=""
            src="/assets/logo/logoDark.png"
          />
        </Link>
      </div>
      <div className="grid lg:grid-cols-4 sm:grid-cols-3 ">
        <div>
          <h1 className="text-xl font-black">
            Discover iclimb <span className="text-[#0131B9]">.</span>{" "}
          </h1>
          <div className="flex flex-col gap-[0.75rem] text-[0.875rem] pt-10">
            <a>About Us</a>
            <a>Methodologies</a>
            <a>Technologies</a>
            <a>Certifications</a>
            <a>Our Services</a>
            <a>Dedicated Teams</a>
            <a>Staff Augmentation</a>
            <a>Software Outsourcing</a>
            <a>Expertise</a>
            <a>Diversity</a>
            <a>Social Responsibility</a>
            <a>Senior Advisor Program</a>
          </div>
        </div>
        <div>
          <h1 className="text-xl font-black">
            Resources<span className="text-[#0131B9]">.</span>{" "}
          </h1>
          <div className="flex flex-col gap-[0.75rem] text-[0.875rem] pt-10">
            <a>Case Studies</a>
            <a>Blog</a>
            <a>Press</a>
            <a>Software Development Insights</a>
            <a>Technologies Insights</a>
            <a>Industries Insights</a>
            <a>Technology Resource Center</a>
            <a>Client Referral Program</a>
          </div>
        </div>
        <div>
          <h1 className="text-xl font-black">
            Careers<span className="text-[#0131B9]">.</span>{" "}
          </h1>
          <div className="flex flex-col gap-[0.75rem] text-[0.875rem] pt-10">
            <a>Job Opportunities</a>
            <a>Talent Referrals</a>
          </div>
        </div>
        <div>
          <h1 className="text-xl font-black">
            Get in touch<span className="text-[#0131B9]">.</span>{" "}
          </h1>
          <div className="flex flex-col gap-[0.75rem] text-[0.875rem] pt-10">
            <div className="flex flex-row items-start justify-start gap-[0.5rem] mb-4">
              <Link to="contact" smooth={true} offset={-260} duration={500} onClick={handleNav}>
                <button className="cursor-pointer py-[8px] px-[14px] bg-white rounded-lg border-[1px] border-solid border-gray-300 hover:bg-[#0131B9] hover:text-white hover:border-[#c0c0c0]">
                  Contact Us
                </button>
              </Link>
              <Link to="contact" smooth={true} offset={-260} duration={500} onClick={handleNav}>
                <button className="cursor-pointer py-[0.475rem] px-[0.812rem] bg-[#0131B9] text-white rounded-lg border-[1px] border-solid hover:bg-[#5b82ec] hover:border-[#778ecc]">
                  Schedule a call
                </button>
              </Link>
            </div>
            <div className="flex flex-row items-start justify-start gap-[0.312rem]">
              <img loading="lazy" alt="" src="/assets/Icons/call.svg" />
              <div> 9599598301,  0124-4376471 </div>
            </div>

            <p className="leading-[1.5rem] py-8 font-bold w-4/6 ">
              Get insights from the experts on building and scaling technology
              teams.
            </p>
            {/* <div className="w-3/4 ">
              <p>Your e-mail address</p>
              <div className="flex justify-between gap-2  ">
                <input type="text" className="rounded-lg " />
                <div className="flex justify-center items-center relative ">
                  <CiMail className="absolute left-2 text-3xl" />
                  <button className="px-11 py-3 rounded-lg border bg-white">
                    Subscribe
                  </button>
                </div>
              </div>
            </div> */}

            {/* <div className="flex pb-[0.093rem] gap-[0.75rem]">
              <input className="w-5 rounded-sm border-solid" type="checkbox" />
              <div className="flex flex-col items-start justify-start pt-[0.093rem]">
                <p>By subscribing I accept the Privacy Policy.</p>
              </div>
            </div> */}
            <div className="py-5">
              <h3 className="font-bold py-5 text-xl">Follow us</h3>
              <div className="flex flex-row items-start justify-start gap-[1.062rem]">
                <img src="/assets/Icons/linkdin.svg" alt="LinkedIn" />
                <img src="/assets/Icons/fb.svg" alt="Facebook" />
                <img src="/assets/Icons/insta.svg" alt="Instagram" />
                <img src="/assets/Icons/utube.svg" alt="YouTube" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footers;
