import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Banner = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up"
    data-aos-duration="1000" className="w-11/12 sm:w-9/12 mt-[60px] sm:mt-[120px] mx-auto border border-custom rounded-2xl px-[20px] sm:px-[40px] py-[20px] sm:py-[24px] bg-[#F8F8FF] flex flex-col sm:flex-row justify-around items-center">
      <div className="flex flex-col items-center sm:items-start text-center sm:text-left">
        <p className="text-[20px]  sm:text-[36px] font-black py-[16px] sm:py-[20px] leading-tight">
          Drive Progress Transform Businesses.
        </p>
        <button className="bg-[#202CDF] py-[8px] px-[20px] sm:px-[30px] text-white text-sm font-black tracking-[1.5px] uppercase rounded-lg">
          Read More
        </button>
      </div>
      <div className="w-[150px] sm:w-[250px] mt-[20px] sm:mt-0">
        <img src="assets/3D_pic/banner.png" alt="Banner" className="w-full h-auto" />
      </div>
    </div>
  );
};

export default Banner;
