import React, { useState } from "react";
import { Link } from "react-scroll";

const HeroBanner = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <>
      <div
        className="home bg-cover lg:h-[100vh] h-[700px] bg-center"
        style={{ backgroundImage: "url('/assets/heroBg.png')" }}
      >
        <div className="lg:w-[70%] px-3 pl-3 lg:pt-[13%] pt-[20%] sm:pl-[100px] md:[50px] ">
          <h1 className="lg:text-[64px] md:w-full w-2/3 text-white text-[40px] font-bold ">
            "Empowering Innovation, Delivering Excellence"{" "}
          </h1>
          <p className="lg:text-2xl leading-8 font-normal py-5 pr-12 text-xl text-white">
            End the IT Frustration. Get Expert Support Now.
          </p>
          <Link to="contact" smooth={true} offset={-260} duration={500} onClick={handleNav}>  <button className="lg:p-[16px] w-full sm:w-auto mt-10 px-[80px] py-[16px] font-medium text-2xl leading-8 bg-[#0131B9] text-white rounded-xl">
            Schedule a call
          </button></Link>
        </div>
      </div>

      <div className="bg-[#D9D9D9] p-4 w-full flex flex-col sm:flex-row md:p-10 sm:items-center lg:p-28 ">
        <div className="flex flex-col sm:flex-1 flex-none justify-start items-start gap-[32px] ">
          <h1 className="text-[42px] font-normal">Strategy to scale</h1>
          <p className="text-[16px] text-md leading-9 font-normal">
            Every business has a unique path to success. leveraging automation,
            expanding our global talent pool, and forming strategic
            partnerships. This enables us to deliver high-quality, efficient
            solutions while adapting to market demands and fostering sustainable
            growth.
          </p>
          <Link to="contact" smooth={true} offset={-260} duration={500} onClick={handleNav}>
          <button className="bg-[#FAFCFF] sm:m-0 py-[20px] items-center px-[16.5px] rounded-[8px] text-md w-[250px] text-[#0131B9] mx-auto mb-4 sm:mb-10 sm:*:m-auto">
            Talk to our experts
          </button>
          </Link>
        </div>
        <div className=" sm:flex-1 flex-none flex w-full justify-center">
          <div>
            <img src="/Desktop/Normal_pic/heroBanner.png" alt="Hero Banner" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroBanner;
