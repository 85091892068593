import React, { useEffect } from "react";
import { FaPlusCircle } from "react-icons/fa";
import 'aos/dist/aos.css';
import AOS from 'aos';
const Next = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up"
    data-aos-duration="3000" className="w-[90%] mt-[112px] mx-auto bg-[#F8F8FF] flex flex-col items-center justify-center rounded-xl">
      <div className="flex flex-col items-center justify-start pt-4 px-4 pb-12">
        <h1 className="text-3xl md:text-4xl font-medium leading-tight md:leading-[70px] text-center">The next</h1>
        <div className="text-xl md:text-2xl text-center mt-4">
          <h1 className="text-[#7E7E7E] leading-tight md:leading-[2.5rem] font-light">
            Unlock the power to navigate your digital transformation
          </h1>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1  px-5 w-full">
        <div className="relative">
          <img
            src="/assets/next/girl.png"
            alt="Left Side"
            className="object-cover h-full w-full"
          />
          <p className="absolute text-lg font-bold bottom-0 left-2 text-[#FFF] p-2">
            Experience
          </p>
        </div>
        <div className="grid grid-rows-2 ">
          <div className="grid grid-cols-2 ">
            <div className="relative">
              <img
                src="/assets/next/image9.png"
                alt="Top Left"
                className="object-cover h-full w-full"
              />
              <p className="absolute text-lg font-bold bottom-0 left-2 text-[#FFF] p-2">
                Insight
              </p>
            </div>
            <div className="relative">
              <img
                src="/assets/next/image10.png"
                alt="Top Right"
                className="object-cover h-full w-full"
              />
              <p className="absolute text-lg font-bold bottom-0 left-2 text-[#FFF] p-2">
                Innovate
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 ">
            <div className="relative">
              <img
                src="/assets/next/image12.png"
                alt="Bottom Left"
                className="object-cover h-full w-full"
              />
              <p className="absolute text-lg font-bold bottom-0 left-2 text-[#FFF] p-2">
                Accelerate
              </p>
            </div>
            <div className="relative">
              <img
                src="/assets/next/image11.png"
                alt="Bottom Right"
                className="object-cover h-full w-full"
              />
              <p className="absolute text-lg font-bold bottom-0 left-2 text-[#FFF] p-2">
                Assure
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 my-10 px-5 w-full">
        {["Service offerings", "Explore industries", "Our platforms", "Navigate your next"].map((item) => (
          <div key={item} className="p-6 bg-white flex flex-col justify-center items-center text-center rounded-lg hover:bg-[#0131B9] hover:text-white hover:cursor-pointer transition-colors duration-300 w-full">
            <p className="text-xl font-bold pb-2">{item}</p>
            <FaPlusCircle className="text-2xl" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Next;
