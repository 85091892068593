import React from 'react'
import { FaArrowRightLong } from "react-icons/fa6";
import "./stories.css"
import Carosel from '../carosel/Carosel';
const Stories = () => {
   
  return (
    <div  className="testimonial bg-[#F8F8FF] mt-[120px] px-[110px] py-[57px]">
        <div>
            <p className='text-4xl leading-[60px] pb-4'>Customer Success Stories</p>
            <div className='flex items-center content-center w-1/2'>
            <p className='font-medium'>Our Greatest Hits</p>
            <FaArrowRightLong className='text-xl ml-3'/>
            </div>
            
        </div>
    {/* <div className='border border-[#E3E3E3] w-[25%] rounded-lg flex flex-col justify-center items-center content-center mt-12'>
        <div className='bg-white px-[32px] pt-[36px] pb-5 ' >
            <img src="/Desktop/knackLogo.png" className='w-52 ' alt="" />
        </div>
        <div className='relative '>
            <img src="/Desktop/knackHome.png" alt="" className=''/>
            <p className='text-lg absolute top-0 text-white p-8'><span className='text-[#0131B9] text-2xl'>"</span>  Repeat Business is the best testament to a team’s ability to perform, and I have no hesitation in hiring them again. iclimb pleasant collaboration style and high-level acumen rapidly catalyzed significant momentum towards achieving our objectives. 
           <span className='text-[#0131B9] text-2xl'>"</span>

           </p> 
        </div>
    </div> */}
        <Carosel/>
    </div>
  )
}

export default Stories