import React, { useEffect } from 'react'
import "./purpose.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
const Purpose = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up"
    data-aos-duration="2000" className="w-11/12 sm:w-10/12 mt-[120px] mx-auto bg-[#F8F8FF] flex flex-col items-center pt-[3.062rem] pb-[3.687rem] relative gap-[1.25rem] text-[1.5rem] sm:text-[2.188rem] border-[3px] border-solid border-[#0131B933] rounded-xl">   
      <h1 className="tracking-[2px] font-black text-center">
        Our Purpose:
      </h1>
      <div className="w-full sm:w-[49.25rem] lg:text-2xl sm:text-lg leading-[2rem] sm:leading-[2.25rem] font-semibold text-center px-4 sm:px-0">
        Empowering people, businesses, and communities to achieve their next breakthrough.
      </div>
    </div>
  )
}

export default Purpose
