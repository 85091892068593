import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
const AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up"
    data-aos-duration="1000" className="about w-11/12 lg:w-[85%] mt-[120px] m-auto border border-[#CBCBCB] rounded-[16px] bg-[#F8F8FF] p-[20px] sm:p-[34px] flex flex-col text-center justify-center">
      <div className="pb-[30px] sm:pb-[50px]">
        <h1 className="text-2xl sm:text-4xl font-medium pb-[10px]">About us</h1>
        <p className="text-[#7E7E7E] text-base sm:text-2xl font-light w-full sm:w-[80%] md:w-[60%] lg:w-[40%] m-auto leading-[28px] sm:leading-[40px]">
          A global leader in next-generation digital services and consulting
        </p>
      </div>
      <div className="px-[20px] sm:px-[40px] lg:px-[80px] flex flex-col sm:flex-row justify-between text-center gap-8 sm:gap-0">
        <div className="w-full sm:w-[30%] lg:w-[20%]">
          <p className="text-4xl sm:text-5xl font-semibold leading-[60px] sm:leading-[86px]">105</p>
          <p className="text-xs sm:text-sm text-[#333]">countries where we have trusting clients</p>
        </div>
        <div className="w-full sm:w-[30%] lg:w-[20%]">
          <p className="text-lg sm:text-xl font-semibold"><span className="text-4xl sm:text-5xl font-semibold leading-[60px] sm:leading-[86px]">5</span></p>
          <p className="text-xs sm:text-sm text-[#333]">billion total revenue (LTM)</p>
        </div>
        <div className="w-full sm:w-[30%] lg:w-[20%]">
          <p className="text-4xl sm:text-5xl font-semibold leading-[60px] sm:leading-[86px]">550+</p>
          <p className="text-xs sm:text-sm text-[#333]">Training (days) conducted for employees, globally</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
