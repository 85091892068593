import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Navigate } from "react-router-dom";

const ContactForm = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  async function submitForm(e) {
    let frm = e.target.form;
    if (!frm) return console.log("misconfigured");
    const action = frm.getAttribute("action");
    const method = frm.getAttribute("method") || "post";
    let data = {};
    let errors = [];
    frm.querySelectorAll("[name]").forEach((fld) => {
      if (!fld.validity.valid) errors.push(fld);
      if (["checkbox", "radio"].indexOf(fld.type) === -1) { data[fld.name] = fld.value; return; }
      if (fld.checked) data[fld.name] = fld.value
    });
    if (errors.length) {
      errors[0].focus();
      return false;
    }
    // const response = await fetch(`https://iclimbs.in${action}`, {
    //   method: "POST",
    //   body: JSON.stringify(data),
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    // let res = await response;
    // let result = await

    fetch(`https://iclimbs.in${action}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //   console.log("Testing data",data);
        //   <Navigate to={`/thankyou`} />
        window.location.replace(`/thankyou/${data.name}`)
      })
      .catch((err) => { console.log(err) });
  }
  return (
    <div data-aos="fade-up"
      data-aos-duration="1000" className='contact bg-[#E2E3F5] p-8 w-[70%] mt-[120px] m-auto rounded-lg'>
      <div className="text-center">
        <h1 className="text-4xl mb-4">How can we help you?</h1>
      </div>
      <form method={"post"}
        action={"/api/v1/contact/new"}
        onSubmit={(e) => {
          e.preventDefault();
        }}>

        <div className="grid grid-cols-2 lg:gap-x-11 lg:gap-y-5 gap-2">
          <div>
            <frfs-text label="Your Name" name="name" placeholder="Your Name" required="true" editable="true" />
          </div>
          <div>
            <frfs-email label="Work Email" name="email" placeholder="Work Email" required="true" editable="true" />
          </div>
          <div>
            <frfs-tel label="Phone No" name="phone" placeholder="Phone No" required="true" editable="true" cn="in" />
          </div>
          <div>
            <frfs-select required="true" editable="true" api="https://iclimbs.in/api/v1/purpose/list" label="Select A Purpose" />
          </div>
          <div className="col-span-2">
            {/* <textarea id="message" rows={4} placeholder='Enter a description' className="w-full border border-gray-300 rounded-md px-3 py-2"></textarea> */}
            <frfs-editor label="I'm Interested in More about..." name="message" />
          </div>

        </div>
        <div className='w-48 m-auto text-sm'  >
          <e-captcha path="/images/captcha/" name="captcha" />
        </div>
        <div className="text-center py-5 ">
          <button className="py-3 px-6 bg-[#0131B9] hover:bg-[#2282F1] text-sm  text-white font-semibold rounded-lg" onClick={submitForm}>Submit</button>
        </div>
      </form>
      {/* <p className='text-xs text-center mt-8'>Click here to opt out of iclimbs mailing lists.</p> */}
    </div>
  )
}

export default ContactForm;