import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Approach = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up"
    data-aos-duration="2000" className='py-[56px] px-[103px] bg-[#1E2222]'>
      <div className='flex flex-col justify-start gap-[32px]'>
        <p className='text-white font-medium text-2xl tracking-[2.1px]'>Our Approach</p>
        <div className='grid lg:grid-cols-4 grid-cols-2 gap-[144px]'>
          <div className='text-white flex flex-col items-center text-center'>
            <img src="/Desktop/Logo/logo2.png" alt="Client-Centric Solutions" />
            <p className='tracking-[0.5px] pt-[40px]'>Client-Centric Solutions</p>
          </div>
          <div className='text-white flex flex-col items-center text-center'>
            <img src="/Desktop/Logo/logo3.png" alt="Innovation and Technology" />
            <p className='tracking-[0.5px] pt-[40px]'>Innovation and Technology</p>
          </div>
          <div className='text-white flex flex-col items-center text-center'>
            <img src="/Desktop/Logo/logo4.png" alt="Quality and Excellence" />
            <p className='tracking-[0.5px] pt-[40px]'>Quality and Excellence</p>
          </div>
          <div className='text-white flex flex-col items-center text-center'>
            <img src="/Desktop/Logo/logo1.png" alt="Collaboration and Partnership" />
            <p className='tracking-[0.5px] pt-[40px]'>Collaboration and Partnership</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;
