import React from 'react'
import Navbar from '../components/navbar/Navbar';
import HeroBanner from '../components/heroBanner/HeroBanner';
import Services from '../components/services/Services';
import Approach from '../components/approach/Approach';
import Testimonials from '../components/testimonials/Testimonials';
import Clients from '../components/clients/Clients';
import Stories from '../components/stories/Stories';
import Next from '../components/next/Next';
import Banner from '../components/banner/Banner';
import Purpose from '../components/purpose/Purpose';
import AboutUs from '../components/aboutUs/AboutUs';
import ContactForm from '../components/Contactform/ContactForm';
import Footers from '../components/footers/Footers';

const HomePage = () => {
    const start = {
        subTitle: "Start with Confidence",
        title: "Unleash the Power of the Cloud:",
        des: "Cloud services offer a revolutionary way to access computing resources on-demand.",
        stage1: {
            name: "Assessment",
            about: " IT assessment, best cloud solutions for you."
        },
        stage2: {
            name: "Migration",
            about: "Expert cloud migration, minimal disruption."
        },
        stage3: {
            name: "Management",
            about: "We'll manage your cloud for security, performance, and cost."
        },
        tagLine: "Go Cloud. Get Free Consultation",
        btn: "Get Access",
        image: "/Desktop/image2/image12.png"
    };
    const streamline = {
        subTitle: "Streamline Your Tech, Focus on Growth",
        title: "Managed IT services can be your game-changer:",
        des: "Tired of IT headaches slowing down your business? Managed IT services with us can be your game-changer.",
        stage1: {
            name: "Expert IT assessment",
            about: "We analyze your setup, goals, and identify potential issues."
        },
        stage2: {
            name: "Custom managed services",
            about: "proactive maintenance, security & ongoing support."
        },
        stage3: {
            name: "Your dedicated IT team",
            about: "seamless systems, 24/7 support, peace of mind."
        },
        tagLine: "Let us manage your IT, so you can manage your success.",
        btn: "Get Access",
        image: "/Desktop/image2/image 46.png"
    };
    const lifeline = {
        subTitle: "Your IT Lifeline - Exceptional Customer Service",
        title: "Your IT services are only as good as the experience you deliver:",
        des: "Ensuring your IT systems run smoothly and you can focus on what matters most.",
        stage1: {
            name: "Assessment",
            about: "Get fast, reliable support through multiple channels. We're here to help."
        },
        stage2: {
            name: "Migration",
            about: "We prevent downtime by anticipating and solving problems before they happen."
        },
        stage3: {
            name: "Management",
            about: "Get a personalized touch with your own dedicated account manager."
        },
        tagLine: "With our commitment to exceptional customer service, you can rest assured that your IT needs are always in good hands.",
        btn: "Get Access",
        image: "/Desktop/image2/image 52.png"
    };
    return (
        <>
            <Navbar />
            <HeroBanner />
            <Services />
            <Approach />
            <Testimonials data={start} />
            <Testimonials data={streamline} />
            <Testimonials data={lifeline} />
            <Clients />
            <Stories />
            <Next />
            <Banner />
            <Purpose />
            <AboutUs />
            <ContactForm />
            <Footers />
        </>
    )
}

export default HomePage