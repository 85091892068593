import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Clients = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up"
      data-aos-duration="2000" className='w-[85%] mt-[120px] m-auto grid lg:grid-cols-2 grid-cols-1 p-[34px] border border-custom rounded-2xl bg-[#F8F8FF]'>
      <div className="text-[#687580] lg:text-4xl tex-2xl font-bold py-8 w-[75%] sm-w-full">
        <p className="">Many companies rely on our top 1% talent to scale their dev teams.</p>
      </div>
      <div className='grid grid-cols-3 items-center gap-[10px] justify-center content-center'>
        <img src="/Desktop/knackLogo.png" alt="Adani logo" className='w-1/2 ' />
        <img src="/Desktop/ascottLogo.png" alt="Bain logo" className='w-1/2 ' />
        <img src="/Desktop/haraayLogo.png" alt="MC logo" className='w-1/2 ' />
        <img src="/Desktop/stocktutorLogo.png" alt="Tibco logo" className='w-1/2 ' />
        <img src="/Desktop/AdyahLogo.png" alt="Tibco logo" className='w-1/2 ' />
        <img src="/Desktop/pinakinLogo.png" alt="Tibco logo" className='w-1/2 ' />
      </div>
    </div>
  )
}

export default Clients
