import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./carosel.css"
const Carosel = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const data = [
    {
      logo: "/Desktop/knackLogo.png",
      banner: "/Desktop/knackHome.png",
      des: "Strong repeat business shows the team's excellence. I'd gladly hire them again. Their collaborative style and quick grasp of complex issues accelerated progress towards our goals.",
    },
    {
      logo: "/Desktop/stocktutorLogo.png",
      banner: "/Desktop/stockHome.png",
      des: "Climb accelerated our product development and reduced costs. Best vendor decision ever.",
    },
    {
      logo: "/Desktop/haraayLogo.png",
      banner: "/Desktop/haraayHome.png",
      des: "Iclimb is a reliable development partner with deep expertise who adapts seamlessly. Their willingness to challenge assumptions aligns perfectly with our culture, driving even better results",
    },
    {
      logo: "/Desktop/ascottLogo.png",
      banner: "/Desktop/ascottHome.png",
      des: "Strong repeat business shows the team's excellence. I'd gladly hire them again. Their collaborative style and quick grasp of complex issues accelerated progress towards our goals.",
    },
    {
      logo: "/Desktop/AdyahLogo.png",
      banner: "/Desktop/adyahmusic.png",
      des: "Strong repeat business shows the team's excellence. I'd gladly hire them again. Their collaborative style and quick grasp of complex issues accelerated progress towards our goals.",
    }, {
      logo: "/Desktop/pinakinLogo.png",
      banner: "/Desktop/pinakin.png",
      des: "Strong repeat business shows the team's excellence. I'd gladly hire them again. Their collaborative style and quick grasp of complex issues accelerated progress towards our goals.",
    },

  ];

  return (
    <div className="w-full h-fit">
      <Slider {...settings}>

        {data.map((item, index) => (
          <div id='slider-boxes' key={index} className=' bg-white slide-item slides' >
            <div className='px-[32px] flex flex-col justify-center items-center content-center'>
              <img src={item.logo} className='w-auto h-[50px]' alt="" />
              <img src={item.banner} alt="" />
            </div>
            <div className='p'>
              <p><span className="text-[#0131B9] text-bold text-2xl">"</span>{item.des}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carosel;
