import React, { useEffect } from "react";
import { FaArrowRight } from "react-icons/fa6";
import "./services.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Services = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up" data-aos-duration="1000" className="services p-0  py-16  sm:py-[56px] px-5 lg:px-24  bg-[#F0F3FF]">
      <p className="text-4xl font-light leading-[30px]">Our services</p>
      <div className="grid gap-2 lg:grid-cols-3 grid-cols-2 sm:gap-4 md:gap-[24px] pt-[40px]">
        <div className="relatives">
          <div className="overlay"></div> 
          <img
            className="image"
            src="/Desktop/Normal_pic/image1.png"
            alt="Service"
          />
          <div className="absolute bottom-0 left-8 text-white text-left">
            <p className="mb-2 text-2xl font-normal">
              Mobile & Web development
            </p>
            <div className="additional-info flex items-center py-4">
              <p>Explore More</p>
              <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center text-black mx-3 text-xl ">
                <FaArrowRight />
              </div>
            </div>
          </div>
        </div>
        <div className="relatives">
          <div className="overlay"></div>
          <img
            className="image"
            src="/Desktop/Normal_pic/image2.png"
            alt="Service"
          />
          <div className="absolute bottom-5 left-8 text-white text-left ">
            <p className="mb-2 text-2xl font-normal">Manage Services</p>
            <div className="additional-info flex items-center">
              <p>Explore More</p>
             <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center text-black mx-3 text-xl ">
                <FaArrowRight />
              </div>
            </div>
          </div>
        </div>
        <div className="relatives">
          <div className="overlay"></div>
          <img
            className="image"
            src="/Desktop/Normal_pic/image3.png"
            alt="Service"
          />
          <div className="absolute bottom-5 left-8 text-white text-left ">
            <p className="mb-2 text-2xl font-normal">Cybersecurity</p>
            <div className="additional-info flex items-center">
              <p>Explore More</p>
             <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center text-black mx-3 text-xl ">
                <FaArrowRight />
              </div>
            </div>
          </div>
        </div>
        <div className="relatives">
          <div className="overlay"></div>
          <img
            className="image"
            src="/Desktop/Normal_pic/image4.png"
            alt="Service"
          />
          <div className="absolute bottom-5 left-8 text-white text-left ">
            <p className="mb-2 text-2xl font-normal">Cloud Service</p>
            <div className="additional-info flex items-center">
              <p>Explore More</p>
             <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center text-black mx-3 text-xl ">
                <FaArrowRight />
              </div>
            </div>
          </div>
        </div>
        <div className="relatives">
          <div className="overlay"></div>
          <img
            className="image"
            src="/Desktop/Normal_pic/image5.png"
            alt="Service"
          />
          <div className="absolute bottom-5 left-8 text-white text-left">
            <p className="mb-2 text-2xl font-normal">IoT(Internet of Things)</p>
            <div className="additional-info flex items-center">
              <p>Explore More</p>
             <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center text-black mx-3 text-xl ">
                <FaArrowRight />
              </div>
            </div>
          </div>
        </div>
        <div className="relatives">
          <div className="overlay"></div>
          <img
            className="image"
            src="/Desktop/Normal_pic/image6.png"
            alt="Service"
          />
          <div className="absolute bottom-5 left-8 text-white text-left ">
            <p className="mb-2 text-2xl font-normal">Staffing</p>
            <div className="additional-info flex items-center">
              <p>Explore More</p>
             <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center text-black mx-3 text-xl ">
                <FaArrowRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
